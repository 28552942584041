import moment from 'moment'

export const dateConvert = (value, format, inputFormat) => {
  if (value) {
    return inputFormat ? moment(value, inputFormat).format(format) : moment(value).format(format)
  }
  return value
}
export const truncate = (text, length, suffix) => {
  if (text && text.length > length) {
    return text.substring(0, length) + suffix
  } else {
    return text
  }
}

<template>
  <div>
    <template v-if="(isExpandedView && !showWidgetExpanedSecondModal.show) || !isExpandedView">
      <b-row class="mb-2">
        <b-col cols="12">
          <a
            v-if="isActionLinkAsForm"
            :href="isActionLinkAsForm.formUrlWithQryString"
            v-srOnlyNewWindow
            target="_blank"
            class="theme-font-medium form-action-link"
            >{{ data.actionLinkTxt }} <ChevronRightBlue class="chevron-icon ml-1"
          /></a>
          <a v-else href="javascript:void(0)" class="theme-font-medium form-action-link" @click="showFormsToSubmit"
            >{{ data.actionLinkTxt }} <ChevronRightBlue class="chevron-icon ml-1"
          /></a>
        </b-col>
      </b-row>
      <template
        v-if="data.pastSubmissions && (data.pastSubmissions.submittedFormsWithoutCategory.length || data.pastSubmissions.categoryWithSubmittedForms.length)"
      >
        <div class="theme-font-bold color-gray mb-1">Past submissions</div>
        <template v-if="data.pastSubmissions.submittedFormsWithoutCategory.length">
          <b-row
            v-for="form in data.pastSubmissions.submittedFormsWithoutCategory.slice(0, limit)"
            :key="form.responseNumber + form.formName"
            class="mb-2 past-submitted-forms-without-category"
          >
            <b-col class="pr-1">
              <div class="d-flex align-items-center" :class="{ 'expanded-view-past-forms': isExpandedView }">
                <div class="form-res-number pr-2" :class="{ 'text-truncate': !isExpandedView }">
                  <a class="theme-font-bold" @click="getFormDetails(form.formId, form.responseNumber)" href="javascript:void(0)" :title="form.responseNumber">{{
                    form.responseNumber
                  }}</a>
                </div>
                <div class="form-name pr-2 w-25" v-if="isExpandedView">
                  {{ form.formName }}
                </div>
                <div class="pr-2 text-nowrap form-updated-date">
                  {{ formatDate(form.lastUpdatedOn, 'MMM D, YYYY') }}
                </div>
                <div class="preview-status theme-font-bold" :style="{ backgroundColor: form.color }" :title="form.status">
                  {{ form.status }}
                </div>
              </div>
              <div class="d-flex" v-if="form.detail">
                <div :title="form.detail" class="additional-form-info color-gray theme-font-bold" :class="{ 'text-truncate': !isExpandedView }">
                  {{ form.detail }}
                </div>
              </div>
            </b-col>
          </b-row>
        </template>
        <div
          class="forms-without-category-border mb-2"
          v-if="data.pastSubmissions.submittedFormsWithoutCategory.length && data.pastSubmissions.categoryWithSubmittedForms.length"
        ></div>
        <template v-if="data.pastSubmissions.categoryWithSubmittedForms.length">
          <div
            v-for="formCategory in data.pastSubmissions.categoryWithSubmittedForms"
            :key="formCategory.id + formCategory.name"
            class="past-submitted-forms-with-category mb-2"
          >
            <label class="theme-font-bold mb-1">{{ formCategory.name }}</label>
            <b-row
              class="mb-2 past-submitted-forms-with-category-form"
              v-for="form in formCategory.submittedFormsWithCategory.slice(0, limit)"
              :key="form.responseNumber + form.formName"
            >
              <b-col class="pr-1">
                <div class="d-flex align-items-center" :class="{ 'expanded-view-past-forms': isExpandedView }">
                  <div class="form-res-number pr-2" :class="{ 'text-truncate': !isExpandedView }">
                    <a
                      class="theme-font-bold"
                      @click="getFormDetails(form.formId, form.responseNumber)"
                      href="javascript:void(0)"
                      :title="form.responseNumber"
                      >{{ form.responseNumber }}</a
                    >
                  </div>
                  <div class="form-name pr-2 w-25" v-if="isExpandedView">
                    {{ form.formName }}
                  </div>
                  <div class="pr-2 text-nowrap form-updated-date">
                    {{ formatDate(form.lastUpdatedOn, 'MMM D, YYYY') }}
                  </div>
                  <div class="preview-status theme-font-bold" :style="{ backgroundColor: form.color }" :title="form.status">
                    {{ form.status }}
                  </div>
                </div>
                <div class="d-flex" v-if="form.detail">
                  <div :title="form.detail" class="additional-form-info color-gray theme-font-bold" :class="{ 'text-truncate': !isExpandedView }">
                    {{ form.detail }}
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </template>
      </template>
    </template>
    <template v-if="isExpandedView && showWidgetExpanedSecondModal.show">
      <template v-if="formDetails.showFormDetails">
        <LoaderIcon v-if="formDetails.loading" />
        <p v-else-if="formDetails.error" class="text-danger">
          {{ formDetails.error }}
        </p>
        <LocationWidgetContent v-else :data="formDetails.data ? formDetails.data : {}" :isExpandedView="true"></LocationWidgetContent>
      </template>
      <template v-else>
        <template v-if="data.showSearchBar">
          <b-row>
            <b-col>
              <b-form-group>
                <div class="floating-input-field">
                  <b-form-input
                    type="text"
                    id="search-forms"
                    class="form-control"
                    placeholder="Enter a keyword or a short description"
                    v-model="searchForm"
                  ></b-form-input>
                  <label for="search-forms">{{ translateLanguage('Txt_FormBuilder_Enter_a_keyword') }}</label>
                  <BIconSearch class="input-placeholder-icon" />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col> {{ translateLanguage('Txt_FormBuilder_Select_an_item') }} </b-col>
          </b-row>
        </template>
        <b-row>
          <b-col
            cols="12"
            sm="6"
            md="4"
            class="px-2 forms-without-groups-to-submit"
            v-for="(form, fWCIndex) in getFormsWithoutGroup"
            :key="`${fWCIndex}-${form.formName}-${form.formUrlWithQryString}`"
            ><a :href="form.formUrlWithQryString" v-srOnlyNewWindow target="_blank" class="form-box textcolor theme-font-bold" role="button">{{
              form.formName
            }}</a></b-col
          >
        </b-row>
        <b-row class="mt-3 forms-with-groups-category-to-submit" v-for="(groupForm, gIndex) in getFormsWithGroups" :key="`${gIndex}-${groupForm.name}`">
          <b-col cols="12" class="mb-n2">
            <label class="theme-font-bold font-18">{{ groupForm.name }}</label>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            md="4"
            class="mt-3 px-2 forms-with-groups-to-submit"
            v-for="(gForm, gFWCIndex) in groupForm.formsWithCategory"
            :key="`${gFWCIndex}-${gIndex}-${gForm.formName}-${gForm.formUrlWithQryString}`"
            ><a :href="gForm.formUrlWithQryString" v-srOnlyNewWindow target="_blank" class="form-box textcolor theme-font-bold" role="button">{{
              gForm.formName
            }}</a></b-col
          >
        </b-row>
        <b-row v-if="!getFormsWithoutGroup.length && !getFormsWithGroups.length">
          <b-col cols="12" class="theme-font-bold">{{ `No forms found with a keyword "${searchForm}"` }}</b-col>
        </b-row>
      </template>
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ChevronRightBlue from '../../../assets/svg/chevron-right-blue.svg'
import LoaderIcon from '../../../assets/svg/loader.svg'
import { BIconSearch } from 'bootstrap-vue'
import { dateConvert } from '../../../utilities/filters.js'
export default {
  name: 'FormBuilderWidgetContent',
  data() {
    return {
      searchForm: '',
    }
  },
  components: {
    ChevronRightBlue,
    LoaderIcon,
    BIconSearch,
    LocationWidgetContent: () => import('../location-widget/LocationWidgetContent.vue'),
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isExpandedView: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: '',
    },
  },
  computed: {
    getFormsWithoutGroup() {
      return this.data.formsWithoutCategory.filter((form) => form.formName.toLowerCase().includes(this.searchForm.toLowerCase()))
    },
    getFormsWithGroups() {
      const result = this.data.categoryWithForms.map((category) => {
        category = Object.assign({}, category)
        if (category.name.toLowerCase().includes(this.searchForm.toLowerCase())) {
          return category
        } else {
          category.formsWithCategory = category.formsWithCategory.filter((form) => form.formName.toLowerCase().includes(this.searchForm.toLowerCase()))
          return category
        }
      })
      return result.filter((res) => res.formsWithCategory.length)
    },
    limit() {
      return this.isExpandedView ? undefined : this.data.dashboardItemCnt
    },
    isActionLinkAsForm() {
      const totalForms = this.getFormsWithoutGroup.length + this.getFormsWithGroups.length

      if (totalForms === 1) {
        if (this.getFormsWithoutGroup.length) {
          return this.getFormsWithoutGroup[0]
        }
        if (this.getFormsWithGroups.length) {
          return this.getFormsWithGroups[0].formsWithCategory.length === 1 ? this.getFormsWithGroups[0].formsWithCategory[0] : false
        }
      }

      return false
    },
    ...mapState({
      showWidgetExpanedSecondModal: (state) => state.shared.showWidgetExpanedSecondModal,
      formDetails: (state) => state.shared.formDetails,
    }),
  },
  methods: {
    formatDate(date, format) {
      return dateConvert(date, format)
    },
    showFormsToSubmit() {
      this.$store.commit('shared/setShowWidgetExpanedSecondModal', {
        show: true,
        widgetExpandedClick: !!this.isExpandedView,
      })
      this.$emit('showExpandedView')
    },
    getFormDetails(formId, responseNumber) {
      if (this.mode !== 'Preview') {
        this.showFormsToSubmit()
        this.$store.dispatch('dashboard/getFormDetails', { formId, responseNumber })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.preview-status {
  border-radius: 13px;
  padding: 3px 8px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-box {
  border: 1px solid #dddddd;
  background: #8080801a;
  border-radius: 3px;
  padding: 25px 15px;
  display: flex;
  cursor: pointer;
  font-size: 15px;
  height: 90px;
  align-items: center;
  color: #000;
  text-decoration: none;
  overflow: hidden;
  flex-wrap: wrap;
}
.additional-form-info {
  margin-left: 30%;
}
.past-submitted-forms-with-category,
.forms-without-category-border {
  border-bottom: 2px solid #dee2e6 !important;
}
.past-submitted-forms-with-category:last-child {
  border-bottom: 0px solid #dee2e6 !important;
}
.form-res-number {
  min-width: 30% !important;
  max-width: 30% !important;
}
</style>
